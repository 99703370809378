import { Icon } from '@iconify/react'
import React, { useContext, useState } from 'react'
import Section from '../Section'
import { mint } from '../../utils/web3mint'
import { toast } from 'react-toastify'
import { walletContext } from '../../contexts/walletContext'

export default function MintCard() {
  const { setMint, price, totalSupply } = useContext(walletContext)

  const handleMint = async () => {
    let txn = await mint();
    if(txn.hash.length > 0){
      toast.success(`Minting successfull`)
      setMint(mint => !mint)
    }
  }
  return (
    <Section className="cs-mint_secton">
      <Section tag='h2' className="cs-font_22 text-uppercase cs-normal cs-m0 text-center">JOIN NOW <br />! Limited availability</Section>
      <Section className="cs-height_25 cs-height_lg_25" />
      <Section className="cs-mint_avatar text-center"><img src="/images/MH_original.png" alt="" /></Section>
      <Section className="cs-height_50 cs-height_lg_30" />
      <ul className="cs-list cs-style2 cs-mp0 cs-primary_color cs-primary_font">
        <li>
          <Section className="cs-list_left">Minted</Section>
          <Section className="cs-list_right">{totalSupply} / 2984 Total</Section>
        </li>
        <li>
          <Section className="cs-list_left">Price</Section>
          {/* TODO: Live price */}
          <Section className="cs-list_right">{price} ETH</Section>
        </li>
      </ul>
      <Section className="cs-height_25 cs-height_lg_25" />
      <button onClick={handleMint} className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100"><span>Mint Now</span></button>
      <Section className="cs-height_15 cs-height_lg_15" />
      <Section tag='p' className="cs-m0 text-center">Current: Public round 1.</Section>
    </Section>
  )
}
