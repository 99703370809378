import { Icon } from '@iconify/react';
import React from 'react'
import Slider from "react-slick";
import Section from '../Section';
import CardStype2 from '../Card/CardStype2'

export default function RoadmapSlider() {
  const data = [
    {
      phase:'2020', 
      title:'The start',
      subtitle:'Land Surveying and planning are underway. Project started to take shape.',
    },{
      phase:'2022', 
      title:'Due diligence',
      subtitle:'Contracts, Ideas and Planning are being reviewed and finalized. Excavation began.',
    },{
      phase:'2023', 
      title:'Web3 Development',
      subtitle:'Development of the Web3 platform begins. The first contracts are deployed.',
    },{
      phase:'2024', 
      title:'DAAB',
      subtitle:'Decentralized Autonomous Advisory Board that are formed from the members will decide the project next goal.',
    },{
      phase:'2025', 
      title:'The Launch',
      subtitle:'First 2 Tracks built. Ribbon cutting ceremony. Open to public',
    },{
      phase:'2026', 
      title:'Expansion',
      subtitle:'First Startup space is operational. First 2 Tracks are fully operational. 4 more tracks are being built.',
    },{
      phase:'2027', 
      title:'100% Operational',
      subtitle:'6 tracks are fully operational. Second Startup space is functional.',
    }
  ]
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="material-symbols:chevron-left-rounded" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="material-symbols:chevron-right-rounded" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <Slider {...settings} className='cs-gap-24 cs-awwow_style_1 cs-type1'>
      {data.map((item, index)=> (
        <Section key={index}>
          <CardStype2 
            cardNumber={index+1}
            phase={item.phase}
            title={item.title}
            subtitle={item.subtitle}
          />
        </Section>
      ))}
    </Slider>
  )
}
