import contractWhite from '../contract/ABIwhite.json';
import contractMinter from '../contract/ABIminter.json';
import contractNFT from '../contract/ABInft.json' //! Update for production
import { ethers } from 'ethers';
import { CONFIG_CONTRACT_WHITELIST, CONFIG_CONTRACT_MINTER, CONFIG_CONTRACT_NFT} from '../config';

export const ethereum = window.ethereum;


// check if metamask extension is installed on the browser
export const isMetaMaskInstalled = () =>{
    if(ethereum){
        return true;
    }

    return false;
}


// connect to metakmask wallet
export const connectWallet = async () =>{
    const accounts = await ethereum.request({method: 'eth_requestAccounts'});
    //await checkSuper(accounts[0]);
    return accounts;
}

// connect to metakmask wallet
export const connectAccount = async () =>{
    const accounts = await ethereum.request({method: 'eth_accounts'});
    //await checkSuper(accounts[0]);
    return accounts;
}


// disconnect metamask wallet
export const disconnectWallet = () =>{
    localStorage.removeItem('isWalletConnected');
    window.location.reload();
}

// check metamask on disconnect
export const onMetamaskDisconnect = () =>{
    ethereum.on('disconnect', () =>{
        console.log('Disconnected');
    });
}


// check metamask on connected
export const onMetamaskconnect = async () =>{
    const chainId = await getChainId();
    ethereum.on('connect', () =>{
        console.log(chainId);
    });
}

// on chain change
export const onChainChange = () =>{
    ethereum.on('chainChanged', (_chainId) =>{
        return parseInt(_chainId);
    });
}

export const getChainId = async () =>{
    const chainId = await ethereum.request({ method: 'eth_chainId' });

    return parseInt(chainId);
}


export const isWalletConnected = () => {
    if(localStorage.getItem('isWalletConnected') === 'true'){
        return true
    }

    return false;
}

export const checkSuper = async (address) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = CONFIG_CONTRACT_WHITELIST;
        const nftContract = new ethers.Contract(contractAddress, contractWhite, signer);

        try{
            let isSupers = await nftContract.isSuper(address);
            if (isSupers === true){
                return true;
            }
            else{
                return false;
            }
        }catch(err){
            return false;
        }
        //console.log("aftercall isSuper:", isSuper);

    }

}

export const checkWhite = async (address) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = CONFIG_CONTRACT_WHITELIST;
        const nftContract = new ethers.Contract(contractAddress, contractWhite, signer);

        try{
            let isSupers = await nftContract.verifyUser(address);
            if (isSupers === true){
                return true;
            }
            else{
                return false;
            }
        }catch(err){
            return false;
        }
        //console.log("aftercall isSuper:", isSuper);

    }

}

export const checkPrice = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = CONFIG_CONTRACT_MINTER;
        const minterContract = new ethers.Contract(contractAddress, contractMinter, signer);
        try{
            let price = await minterContract.getPrice();
            price = ethers.utils.formatEther(price);
            console.log("aftercallcheck price:", price);
            return price;
        }catch(err){
            console.log("aftercallcheck price:", err);
            return "0";
        }


    }

}

export const checkTotalSupply = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = CONFIG_CONTRACT_NFT;
        const minterContract = new ethers.Contract(contractAddress, contractNFT, signer);
        try{
            let totalSupply = await minterContract.totalSupply();
            console.log("aftercallcheck:", totalSupply);
            totalSupply = ethers.utils.formatUnits(totalSupply, 0);
            // let left = await minterContract.getLE;
            // console.log("aftercallcheck Left:", left.toString());
            return totalSupply;
        }catch(err){
            console.log("aftercallcheck totalSupply error:", err);
            return "0";
        }

    }
}