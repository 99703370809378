import { createContext, useState } from "react"
import { isWalletConnected, connectWallet, checkSuper, checkWhite, checkPrice, checkTotalSupply } from '../utils/web3functions'

export const walletContext = createContext({
    account:'',
    setAccount:() => {},
    isSuper: false,
    setSuper: () => {},
    isWhite: false,
    setIsWhite: () => {},
    price: '0',
    setPrice: () => {},
    totalSupply: "0",
    setTotalSupply: () => {},
});

const WalletContext = ({ children }) => {
    const [account, setAccount] = useState('')
    const [isSuper, setSuper] = useState(false)
    const [isWhite, setIsWhite] = useState(false)
    const [price, setPrice] = useState('0')
    const [totalSupply, setTotalSupply] = useState("0")
    const value = { account, setAccount, isSuper, setSuper, isWhite, setIsWhite, price, setPrice, totalSupply, setTotalSupply}
    const handleConnection = () => {
        if( isWalletConnected() ){
            const handleWalletConnect = async () => {
                let accounts = await connectWallet();
                if( accounts.length ){
                    setAccount(accounts[0]);
                    let getprice = await checkPrice();
                    setPrice(getprice);
                    let totalSupply = await checkTotalSupply();
                    setTotalSupply(totalSupply);
                    let isSupers = await checkSuper(account);
                if (isSupers === true){
                     setSuper(true);
                }
                else{
                    setSuper(false);
                }
                    let isWhites = await checkWhite(account);
                if (isWhites === true){
                        setIsWhite(true);
                }
                else{
                        setIsWhite(false);
                }
                }
                
            }
            
            handleWalletConnect()
        }
    }

    handleConnection();
    
    return (
        <walletContext.Provider value={value}>
            {children}
        </walletContext.Provider>
    )
}

export default WalletContext

