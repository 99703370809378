import React from 'react'
import { Link } from 'react-router-dom'
import Section from '../Section'


export default function Button2({btnLink, btnText, variant, children}) {
  return (
    <Link to="" className={`cs-btn ${variant?variant:''}`}>
      {children}
      <Section tag='span'>{btnText}</Section>
    </Link>
  )
}

// async function addToken() {
//   const tokenAddress = '0xd00981105e61274c8a5cd5a88fe7e037d935b513';
// const tokenSymbol = 'TUT';
// const tokenDecimals = 18;
// const tokenImage = 'http://placekitten.com/200/300';

// try {
//   // 'wasAdded' is a boolean. Like any RPC method, an error can be thrown.
//   const wasAdded = await window.ethereum.request({
//     method: 'wallet_watchAsset',
//     params: {
//       type: 'ERC20',
//       options: {
//         address: tokenAddress, // The address of the token.
//         symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 characters.
//         decimals: tokenDecimals, // The number of decimals in the token.
//         image: tokenImage, // A string URL of the token logo.
//       },
//     },
//   });

//   if (wasAdded) {
//     console.log('Thanks for your interest!');
//   } else {
//     console.log('Your loss!');
//   }
// } catch (error) {
//   console.log(error);
// }
// }