import contractWhite from '../contract/ABIwhite.json';
import contractMinter from '../contract/ABIminter.json'; //! Update for production
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum} from '../utils/web3functions';
import { CONFIG_CONTRACT_WHITELIST, CONFIG_CONTRACT_MINTER} from '../config';


export const mint = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = CONFIG_CONTRACT_MINTER;
        const minterContract = new ethers.Contract(contractAddress, contractMinter, signer);
        const price = await minterContract.getPrice();
        let txnHash = await minterContract.mint({
            //gasLimit: "550000",
            value: price.toString()
        })
        return txnHash
    }

}

export const addClosedWhitelist = async (address) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = CONFIG_CONTRACT_WHITELIST;
        const nftContract = new ethers.Contract(contractAddress, contractWhite, signer);
        try{
            let txnHash = await nftContract.addClosedWhitelist(address);
            //console.log("TX:", txnHash);
            return txnHash
        }catch(e){
            //console.log("ERROR:", e.message);
            return(e);
        }
    
    }

}

export const checkSuper = async (address) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = CONFIG_CONTRACT_WHITELIST;
        const nftContract = new ethers.Contract(contractAddress, contractWhite, signer);

        let isSuper = await nftContract.isSuper(address);
        if (isSuper === true){
            console.log("isSuper:", isSuper);
            localStorage.setItem("isSuper", true);
        }
        else{
            console.log("isSuper:", isSuper);
            localStorage.setItem("isSuper", false);
        }
        return isSuper; // Return true or false
    }

}

