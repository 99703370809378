import { Icon } from '@iconify/react'
import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import FunFact from '../FunFact'
import Hero from '../Hero'
import Section from '../Section'
import SectionHeading from '../SectionHeading'
import RoadmapSlider from '../Slider/RoadmapSlider'
import Spacing from '../Spacing'
import { Analytics } from '@vercel/analytics/react';

export default function Home() {
  pageTitle('MechaHUB | Home');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const accordionData1 = [
    {
      question: 'How to Mint an NFT?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.'
    },
    {
      question: 'What happens once purchase NFT?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.'
    },
    {
      question: 'How can I obtain NFTs?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.'
    },
    {
      question: 'Do NFTs appreciate in value?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.'
    }
  ]
  return (
    <>

      <Hero 
        title='Motorsports &<br /> Blockchain <br />Training Facility<br />'
        mintNumber='298'
        mintMax='2984'
        mintPrice='1'
        mintDeathLine='Pre-sale round 1' 
        bgUrl='/images/hero_img_3.jpeg'
        heroImageUrl='/images/hero_img_1.png' 
        animatedUrl='/images/hero_img_sm.png' 
        variant='cs-type1'
        bubble 
      />
      <Spacing lg='100' md='70'/>
      <Section className="container">
        <Section className="row">
          <Section className="col-lg-3 col-6">
            <Section className='cs-light_bg text-center cs-radius_10'>
              <Spacing lg='60' md='60'/>
              <FunFact 
                number='298+'
                title='hectars of land'
              />
              <Spacing lg='60' md='60'/>
            </Section>
            <Spacing lg='25' md='25'/>
          </Section>
          <Section className="col-lg-3 col-6">
            <Section className='cs-light_bg text-center cs-radius_10'>
              <Spacing lg='60' md='60'/>
              <FunFact 
                number='6'
                title='different motorsports tracks'
              />
              <Spacing lg='60' md='60'/>
            </Section>
            <Spacing lg='25' md='25'/>
          </Section>
          <Section className="col-lg-3 col-6">
            <Section className='cs-light_bg text-center cs-radius_10'>
              <Spacing lg='60' md='60'/>
              <FunFact 
                number='183'
                title='days a year reserved for members ONLY'
              />
              <Spacing lg='60' md='60'/>
            </Section>
            <Spacing lg='25' md='25'/>
          </Section>
          <Section className="col-lg-3 col-6">
            <Section className='cs-light_bg text-center cs-radius_10'>
              <Spacing lg='60' md='60'/>
              <FunFact 
                number='100+'
                title='incubator spaces for startups'
              />
              <Spacing lg='60' md='60'/>
            </Section>
            <Spacing lg='25' md='25'/>
          </Section>
        </Section>
      </Section>
      <Section tag='section' id='roadmap'>
        <Spacing lg='95' md='65'/>
        <Section className="container">
          <Section className="text-center">
            <SectionHeading 
              title='Current Roadmap'
              subtitle='Our Goals'
            />
          </Section>
          <Spacing lg='75' md='55'/>
          <Section className="cs-accent_seperator_1 cs-accent_color"></Section>
          <RoadmapSlider/>
        </Section>
      </Section>
      {/* <Section tag='section' id='faq'>
        <Spacing lg='95' md='70'/>
        <Section className="container">
          <Section className="text-center">
            <SectionHeading 
              title='Questions?'
              subtitle='FAQ'
            />
          </Section>
          <Spacing lg='50' md='30'/>
          <Section className="row">
            <Section className="col-lg-8 offset-lg-2">
              <Accordion variant='' data={accordionData1} openPosition={0} questionNumberStarter={1}/>
            </Section>
          </Section>
        </Section>
      </Section> */}
      <Spacing lg='95' md='65'/>
      <Analytics />
    </>
  )
}
