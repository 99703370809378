import { Icon } from '@iconify/react'
import React, { useContext, useState } from 'react'
import Section from '../Section'
import { addClosedWhitelist } from '../../utils/web3mint'
import { toast } from 'react-toastify'
import { walletContext } from '../../contexts/walletContext'
import Spacing from '../Spacing'

export default function WhiteListCard() {
  const [addressToAdd, setAddress] = useState("");
  const { setMint } = useContext(walletContext)
  const handleAdding = async () => {

    const answer = await addClosedWhitelist(addressToAdd);
    if (answer instanceof Error) {
      if (answer.error !== undefined) {
        toast.error(answer.error.message);
      }else{
        toast.error(answer.message);
      }
    }else{
      toast.success(`Succesfully added to whitelist`);
    }
  }
  return (
    <Section className="cs-mint_secton">
      <Section tag='h2' className="cs-font_22 text-uppercase cs-normal cs-m0 text-center">Adding to whitelist <br /></Section>
      <Section className="cs-height_25 cs-height_lg_25" />
      <Section className="cs-height_50 cs-height_lg_30" />
      <ul className="cs-list cs-style2 cs-mp0 cs-primary_color cs-primary_font">
        {/* <li>
          <Section className="cs-list_left">Remaining</Section>
          <Section className="cs-list_right">1 / 725 Minted</Section>
        </li> */}
        <li>
        <Section className="cs-height_25 cs-height_lg_25" />
        <Section className="col-lg-12">

            <label>Ethereum Address </label>
            <input type="text" className="cs-form_field_adr" value={addressToAdd} onChange={(e)=>setAddress(e.target.value)}></input>

          <Spacing lg='30' md='30'/>
        </Section>
          
        </li>
      </ul>
      <Section className="cs-height_25 cs-height_lg_25" />
      <button onClick={handleAdding} className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100"><span>Sign and Add</span></button>
      <Section className="cs-height_15 cs-height_lg_15" />
      <Section tag='p' className="cs-m0 text-center">Address can be added only once.</Section>
    </Section>
  )
}
